import { useTranslation } from 'react-i18next';
import { useCalculationFormContext } from '../types';
import { Select } from 'components';
import { MenuItem } from '@material-ui/core';
import { Telematics } from 'schema/serverTypes';
import { useTelematicsData } from '../../../dictionaries/Telematics/useTelematicsData';

export const TelematicsSelect = ({ telematics }: { telematics?: Telematics }) => {
  const { t } = useTranslation();
  const { control } = useCalculationFormContext();

  const { rows } = useTelematicsData();

  const getName = (value: Telematics) => {
    switch (value) {
      case Telematics.Caesar:
        return t('TelematicsType.Caesar');
      case Telematics.XPro:
        return t('TelematicsType.XPro');
      case Telematics.XproKrimistop:
        return t('TelematicsType.XproKrimistop');
      case Telematics.Krimistop:
        return t('TelematicsType.Krimistop');
      case Telematics.Client:
        return t('TelematicsType.Client');
      case Telematics.None:
        return t('No');
      default:
        return t('Unknown');
    }
  };

  let options = [
    {
      id: 0,
      telematics: Telematics.None,
      name: getName(Telematics.None),
      installationCost: 0,
      subscriptionFee: 0,
      enabled: true,
    },
    ...rows.filter((row) => row.enabled),
  ];
  const optionExists = options.some((option) => option.telematics === telematics);

  // If no object with the specified telematics is found, add a new option
  if (!optionExists && telematics) {
    options.push({
      id: -1,
      telematics: telematics,
      name: getName(telematics),
      installationCost: 0,
      subscriptionFee: 0,
      enabled: true,
    });
  }

  return (
    <Select
      name="telematics"
      label={t('Telematics')}
      control={control}
      rules={{
        validate: {
          isClient: (value: Telematics | undefined) =>
            value === Telematics.Local ||
            value === Telematics.Client ||
            value === Telematics.None ||
            t('Данный тип телематики недоступен.'),
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.telematics} value={option.telematics}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};
